.boxstyle {
    width: 383px;
    margin: auto;
    background: black;
    padding: 40px;
    position: relative;
    top: 60px;
  }
  textarea{
    font-size:18px;
    background: #4b4747;
    color: white;
  }
  .custom-select{
      background: #4b4747;
      color: white;
  }
  .pageLayout{
    height: 730px;
    background-color: black;
    background-repeat: no-repeat;
    background-attachment: fixed;  
    background-size: cover;
    backface-visibility: hidden;  
  }
  .thirdpartyLogin:focus{
    outline: transparent;
  }